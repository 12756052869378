export const adminCodes = {
  INCOMING_ORDERS_FROM_SHOPPING_CART: 'I_O_from_shopping_cart',
  INCOMING_ORDERS_FROM_3D_PRINTING: 'I_O_from_3d_printing',
  INCOMING_ORDERS_FROM_CUSTOM_ORDERS: 'I_O_from_custom_orders',
  INCOMING_ORDERS_FROM_ESTIMATE_PAGE: 'I_O_from_estimate_page',
  INCOMING_ORDERS_FROM_UPLOAD_FILE_PAGE: 'I_O_from_uploadfile_page',
  NEW_CUSTOM_APPROBATION: 'new_customer_approbation',
  PQ_CUSTOMER_APPROBATION_BEFORE_JO: 'PQ_customer_approbation_before_JO',
  JO_APPROVAL_BEFORE_NEXT_SERVICE: 'JO_approval_before_next_service',
  ADDING_PAYMENTS_IS_AVAILABLE: 'adding_payments_is_available',
  PAYMENTS_CAN_BE_ADDED_AFTER_INVOICE: 'payments_can_added_after_invoice',
  ESTIMATE_PAGE_MULTIPLE_UPLOAD: 'estimate_page_multiple_upload',
  ESTIMATE_PAGE_MULTIPLE_VIEWER: 'estimate_page_multiple_viewer',
  PRICING_CALCULATOR_WIDTH: 'pricing_calculate_with',
  ESTIMATE_PAGE_RHINO_ROUTE: 'estimate_page_rhino_route',
  ESTIMATE_PAGE_RHINO_COMMAND: 'estimate_page_rhino_command',
  ESTIMATE_PAGE_ALLOWED_EXTENSIONS: 'estimate_page_allowed_extensions',
  PRINT_FEE_ITEM_TYPE_CODE: 'print_fee_item_type_code',
  CASTING_FEE_ITEM_TYPE_CODE: 'casting_fee_item_type_code',
  CONTACT_US_MAIL_TO: 'contact_us_mail_to',
  NEWSLETTER_SUBSCRIPTION_EMAIL_TO: 'newsletter_subscription',
  MAX_FILE_UPLOAD_LIMIT_MB: 'max_file_upload_limit_mb',
  STL_DESIGN_FILE_PIRO_ATTRIBUTE: 'stl_design_file_piro_attribute',
  STL_DESIGN_FILE_NAMES_PIRO_ATTRIBUTE: 'stl_design_file_names_piro_attribute',
  ORIGINAL_DESIGN_FILE_PIRO_ATTRIBUTE: 'original_design_file_piro_attribute',
  DEFAULT_PAGE_TITLE: 'default_page_title',
  DEFAULT_PAGE_TEMPLATE_WGEN_CREATING_TEMPLATE:
    'default_page_template_when_creating_template',
  ENABLE_ADMIN_ORDER_CONFIRMATION_EMAIL:
    'enable_admin_order_confirmation_email',
  ENABLE_CUSTOMER_ORDER_CONFIRMATION_EMAIL:
    'enable_custmer_order_confirmation_email',
  SHOW_CREDIT_CARD_ENTRY_FIELDS_ON_PAYMENT_FORM:
    'show_credit_card_entry_fields_on_payment_form',
  SHOW_JOB_ORDERS_ON_PAYMENT_FORM: 'show_job_orders_on_payment_form',
  SHOW_PRICE_QUOTES_AND_SALES_ORDERS_ON_PAYMENT_FORM:
    'show_price_quotes_and_sales_orders_on_payment_form',
  JOB_IMAGES: 'job_images',
  JOB_NOTES: 'job_notes',
  SEND_NOTIFICATION_AFTER_NEW_USER_REGISTRATION_TO:
    'send_notification_after_new_user_registration_to',
  SEND_NOTIFICATION_AFTER_SO_INSERT_TO_SHOP_MANAGER:
    'send_notification_after_so_insert_to_shop_manager',
  CUSTOMER_CATEGORY_FOR_REGISTRATION: 'customer_category_for_registration',
  SHOW_PRICE_ON_PRODUCT_DETAIL_PAGE: 'ADM_CP_ShowPricesOnProductDetailPage',
  ADM_CP_ShowPricesOnCartPage: 'ADM_CP_ShowPricesOnCartPage',
  ADM_CP_ShowPricesOnOrdersPage: 'ADM_CP_ShowPricesOnOrdersPage',
  default_workflow_for_catalog_orders: 'default_workflow_for_catalog_orders',
  ADM_CP_GroupItemsOnCatalogOrdes: 'ADM_CP_GroupItemsOnCatalogOrdes',
  LOGIN_URL: 'login_url',
  LOGOUT_URL: 'logout_redirect_url',
  SHOW_SHOP_MANAGER_ORDERS_IN_MODULES: 'show_shop_manager_orders_in_modules',
  SAVE_SHOP_MANAGER_IN_ORDERS: 'save_store_manager_in_orders',
  REGISTRATION_COUNTRY_LIST_FIRST: 'registration_country_list_first',
  DEFAULT_PAGE_SIZE_FOR_GRIDS: 'default_page_size',
  DEFAULT_METAL_WEIGHT_MEASURE_UNIT: 'default_metal_weight_measure_unit',
  UPON_PQ_APPROVAL_ACTION: 'upon_pq_approval_action',
  REFERENCE_NUMBER_MUST_BE_ENTERED_PQ_APPROVAL:
    'reference_number_must_be_entered_pq_approval',
  REDIRECT_AFTER_FRONTEND_LOGIN: 'redirect_url_after_frontend_login',
  REDIRECT_AFTER_BACKEND_LOGIN: 'redirect_url_after_login',
  AttachJOEnvelopeToEmail: 'Attach_JOEnvelope_To_Email',
  DEFAULT_USER_GROUP: 'default_user_group',
  LIMIT_DISPLAYED_QUOTES_TYPE: 'limit_displayed_quotes_type',
  ENABLE_ACCOUNT_PICKER: 'enable_account_picker',
  NOTIFY_STORE_MANAGER_ORDER: 'notify_store_mgr_order',
  REDIRECT_URL_AFTER_FRONTEND_LOGOUT: 'redirect_url_after_frontend_logout',
  ShowAllCustomersForSuperAdminUsers:
    'show_all_customers_for_super_admin_users',
  SHOW_STONES_ATTRIBUTES_COLUMN_ON_SO: 'show_stones_attr_col_so',
  //Use_Specific_JSN_Pricing: 'Use_Specific_JSN_Pricing',
  display_columns_on_catalog_list_page: 'display_columns_on_catalog_list_page',
  SALES_ORDER_STATUS_ON_CREATE: 'sales_order_status_on_create',
  GenerateOrderCodeWithSuffix: 'generate_order_code_with_suffix',
  ShowSubItemQuantities: 'show_subitem_quantities',
  EstimatePageDefaultWorkflow: 'estimate_page_default_workflow',
  ThreeDItemTypeSKU: '3d_print_item_type_SKU',
  group_items_by_finisher_and_workflow: 'group_items_by_finisher_and_workflow',
  DisplayAttributesOnFrontend: 'display_attributes_on_frontend',
  SquareApplicationID: 'payment_square_appication_id',
  group_items_based_on_workflow_on_uploadandestimate:
    'group_items_based_on_workflow_on_uploadandestimate',
  ShowChildItemsOnProductDetail:
    'show_items_belonging_to_the_current_master_item',
  ShowProductCategoriesOnCatalogPage: 'show_product_categories_on_catalog_page',
  ShowFilterButtonsOnCatalogPage: 'show_filter_buttons_on_catalog_page',
  calculate_weight_for_job_items: 'calculate_weight_for_job_items',
  calculate_total_price_into_price_field_for_product:
    'calculate_total_price_into_price_field_for_product',
  default_date_format: 'default_date_format',
  ShowMetalChangerOnProductDetail: 'show_metal_changer_on_product_detail',
  ADM_CP_ShowPricesOnEstimatePage: 'ADM_CP_ShowPricesOnEstimatePage',
  ShowBackofficeFooter: 'show_backoffice_footer',
  ShowItemSKUOnEstimatePage: 'show_item_sku_on_estimate_page',
  ShowTreatmentsOnEstimatePage: 'show_treatments_on_estimate_page',
  RequestedInfoDuringCheckout: 'requested_info_during_checkout',
  ENABLED_USER_TYPES_ON_REGISTRATION: 'enabled_user_types_on_registration',
  sales_order_shipping_time: 'sales_order_shipping_time',
  shipping_methods_on_checkout_page: 'shipping_methods_on_checkout_page',
  ProductDetailSubtitleSource : "product_detail_subtitle_source",
  AllowPaymentOnThePortalBackoffice : "allow_payment_on_the_portal_backoffice",
  ShowPriority: 'show_priority',
  ShowMinimumBoxSizes: 'show_minimum_box_sizes',
  SHOW_STONE_INFORMATION_ON_PRODUCT_DETAIL: 'show_stone_information_on_product_detail',
  ADM_CP_user_can_define_the_order_due_date: 'user_can_define_the_order_due_date',
  ShowUpdateTreatments : "show_update_treatments",
  ProductReferenceNumberAttributeCode : "product_reference_number_attribute_code",
  CREATE_CUSTOMER_FOR_NEW_REGISTRATION : "create_customer_for_new_registration",
  ESTIMATE_SHOW_MEASURE_UNIT_CHANGER: "ESTIMATE_SHOW_MEASURE_UNIT_CHANGER",
  SHOW_PQ_OR_JO_TOGGLE_IN_THE_CUSTOMER_ORDER_FORM: "show_pq_or_jo_toggle_in_the_customer_order_form",

  SHOW_BACKEND_SHOPPING_CART_PAGE: 'show_backend_shopping_cart_page',
  HIDE_SIGN_UP_SECTION_ON_THE_BACKOFFICE_HOMEPAGE: "hide_sign_up_section_on_the_backoffice_homepage",
  default_customer_type_on_registration: 'default_customer_type_on_registration',
  show_powered_by_piro_link_on_the_backoffice_login : "show_powered_by_piro_link_on_the_backoffice_login",
  default_order_type_on_estimate_page: 'default_order_type_on_estimate_page',
}
